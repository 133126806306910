body {

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap"); */

@font-face {
  font-family: "Lucida";
  src: url("./assets/fonts/lucida.ttf");
}

@font-face {
  font-family: "Acme";
  src: url("./assets/fonts/acme.ttf");
}


@font-face {
  font-family: "Frankfurt";
  src: url("./assets/fonts/frankfrt.ttf");
}