* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expnaded', sans-serif;
}

body {
  background: #ECE9E6;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
background-image: url('./assets/images/sjlisafrankbg2.png');


}